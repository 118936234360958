import {
  Component,
  OnInit,
  Inject,
  Directive,
  HostListener,
} from "@angular/core";
import { Pipe } from "@angular/core";
import { AuthInterceptor } from "src/app/auth/auth-interceptor";
import { HttpClient } from "@angular/common/http";
import { CampaignService } from "../campaign.service";
import { NumberMimeType } from "./mime-type.validator";

import {
  FormGroup,
  FormGroupDirective,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Route } from "@angular/compiler/src/core";
import { Router } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { SuccessComponent } from "src/app/success/success.component";
import * as moment from "moment";
import * as momentT from "moment-timezone";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";
import { AuthService } from "src/app/auth/auth.service";
export interface DialogData {
  campaignId: string;
  campaignCost: string;
  walletBalance: string;
  chargeableCost: string;
  campaignStatus: string;
  mobile: number;
  duplicatesRemoved: number;
  invalidNumbers: number;
}
@Component({
  selector: "app-campaign-create",
  templateUrl: "./campaign-create.component.html",
  styleUrls: ["./campaign-create.component.css"],
})
export class CampaignCreateComponent implements OnInit {
  masks = [];
  form: FormGroup;
  isLoading = false;
  messageRemLength = 160;
  numberOfMessages = 1;
  msg_default_size = 1;
  msg_multiplier = 1;
  file_select = false;
  minDate;
  maxDate;
  maskName = null;
  signatureText = "";
  account;
  contactList;
  templateList;
  constructor(
    private http: HttpClient,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService
  ) {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const currentDay = date.getDate();
    this.minDate = new Date(currentYear - 0, currentMonth, currentDay);
    this.maxDate = new Date(currentYear + 0, currentMonth, currentDay + 7);
  }

  ngOnInit(): void {
    this.campaignService.getAllMasks().subscribe(
      (response: any) => {
        // console.log(response.contactList);
        this.contactList = response.contactList;
        this.templateList = response.messageTemplates;
        this.masks.push({
          value: response.defaultMask,
          viewValue: response.defaultMask,
        });
        for (var i = 0; i < response.additionalMask.length; i++) {
          if (response.additionalMask[i].mask_status == 1) {
            this.masks.push({
              value: response.additionalMask[i].mask,
              viewValue: response.additionalMask[i].mask,
            });
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
    //form validations
    this.form = new FormGroup({
      sourceAddress: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 _~'&-.@]*$"),
        ],
      }),
      campaignName: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      msisdns: new FormControl("", {
        validators: [
          // Validators.pattern("^[0-9]{9}([,][0-9]{9})*$"),
          Validators.maxLength(60000 * 12),
        ],
      }),
      fileSelect: new FormControl(null, {
        validators: [Validators.required],
        asyncValidators: [NumberMimeType],
        // updateOn: "submit",
      }),
      fileName: new FormControl(null, {
        validators: [Validators.required],
      }),
      isOptOutRequired: new FormControl(null, {
        validators: [],
      }),
      scheduleDate: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.maxLength(10),
          Validators.minLength(8),
        ],
        // updateOn: "submit",
      }),
      scheduleTime: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(8),
          Validators.pattern("^[AMamPMpm 0-9:]*$"),
        ],
      }),
      // expiryDate: new FormControl(null, {
      //   validators: [
      //     Validators.required,
      //     Validators.maxLength(10),
      //     Validators.minLength(8),
      //   ],
      // }),
      // expiryTime: new FormControl(null, {
      //   validators: [
      //     Validators.required,
      //     Validators.minLength(7),
      //     Validators.maxLength(8),
      //     Validators.pattern("^[AMamPMpm 0-9:]*$"),
      //   ],
      // }),
      message: new FormControl("", {
        validators: [
          Validators.required,
          Validators.minLength(3),
          // Validators.pattern("^[a-zA-Z0-9-.,_'\"`|:() /]*$"),
        ],
      }),
      scheduleNow: new FormControl(null, {
        validators: [],
      }),
      isContactList: new FormControl("0", {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
        ],
      }),
      contactList: new FormControl(null, {
        validators: [],
      }),
      templateList: new FormControl(null, {
        validators: [],
      }),
      blackOutLogic: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
    this.authService.getAccountDetails().subscribe(
      (response: any) => {
        this.account = response.userData;
        if (this.account?.account_ussage == 1) {
          this.form.controls.blackOutLogic.setValue(0);
          this.form.get("blackOutLogic").setValidators([]);
        } else if (this.account?.account_ussage == 2) {
          this.form.controls.blackOutLogic.setValue(null);
          this.form.get("blackOutLogic").setValidators([Validators.required]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  scheduleNowChange() {
    const newTime = momentT().tz("Asia/kolkata").format("HH:mm A");
    const newDate = momentT().tz("Asia/kolkata").format("YYYY-MM-DD");
    if (this.form.value.scheduleNow == true) {
      //schedule time
      this.form.patchValue({ scheduleTime: newTime });
      this.form.get("scheduleTime").updateValueAndValidity();
      //schedule date
      this.form.patchValue({ scheduleDate: newDate });
      this.form.get("scheduleDate").updateValueAndValidity();

      this.form.get("scheduleTime").disable();
      this.form.get("scheduleDate").disable();
    } else {
      this.form.patchValue({ scheduleTime: null });
      this.form.get("scheduleTime").updateValueAndValidity();
      this.form.patchValue({ scheduleDate: null });
      this.form.get("scheduleDate").updateValueAndValidity();

      this.form.get("scheduleTime").enable();
      this.form.get("scheduleDate").enable();
    }
  }
  onPasteMobileNumbers(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData("text");

    let updatedText = pastedText;
    if (this.form.value.msisdns) {
      updatedText = this.form.value.msisdns + "," + pastedText;
    }
    this.form.patchValue({
      msisdns: formateString(updatedText).replace(/,\s*$/, ""),
    });
  }

  getNumbersCount() {
    // const numberFormatter = Intl.NumberFormat("en-US");
    // return numberFormatter.format(
    //   Number((this.form.value.msisdns.length / 10).toFixed(0))
    // );

    return this.form.value.msisdns.split(",").length;
  }
  createCampaign() {
    // console.log(this.form.valid);
    this.file_select = true;
    if (this.form.invalid) {
      return;
    }
    let scheduleNewTime = moment(
      this.form.value.scheduleTime,
      "hh:mm A"
    ).format("HH:mm:ss");
    if (this.form.value.scheduleNow == true) {
      scheduleNewTime = momentT()
        .add(2, "minutes")
        .tz("Asia/kolkata")
        .format("HH:mm:ss");
    }
    this.isLoading = true;
    //number file
    if (this.form.value.isContactList == 0) {
      this.campaignService
        .createCampaign(
          this.form.value.sourceAddress,
          this.form.value.campaignName,
          this.form.value.msisdns,
          this.form.value.fileSelect,
          this.form.value.isOptOutRequired ? "1" : "0",
          momentT(this.form.value.scheduleDate)
            .tz("Asia/kolkata")
            .format("YYYY-MM-DD"),
          scheduleNewTime,
          // this.form.value.expiryDate,
          // this.form.value.expiryTime,
          this.form.value.message,
          this.form.value.scheduleNow ? "1" : "0",
          this.form.value.blackOutLogic
        )
        .subscribe(
          (response: any) => {
            this.isLoading = false;

            if (response.status == "success") {
              this.file_select = false;
              const dialogRef = this.matDialog.open(CampaignPaymentComponent, {
                width: "630px",
                height: "630px",
                data: {
                  campaignId: response.campaignId,
                  campaignCost: response.campaignCost,
                  walletBalance: response.walletBalance,
                  chargeableCost: response.chargeableCost,
                  campaignStatus: response.campaignStatus,
                  mobile: response.userMobile,
                  duplicatesRemoved: response.duplicatesRemoved,
                  invalidNumbers: response.invalidNumbers,
                },
              });
            } else {
              console.log("invalid");
            }
          },
          (error) => {
            this.isLoading = false;
            console.log(error);
          }
        );
      //console.log(this.form);
    }
    //contact list
    else if (this.form.value.isContactList == 1) {
      this.campaignService
        .createCampaignContactList(
          this.form.value.sourceAddress,
          this.form.value.campaignName,
          this.form.value.msisdns,
          this.form.value.isOptOutRequired ? "1" : "0",
          momentT(this.form.value.scheduleDate)
            .tz("Asia/kolkata")
            .format("YYYY-MM-DD"),
          scheduleNewTime,
          this.form.value.message,
          this.form.value.contactList,
          this.form.value.scheduleNow ? "1" : "0",
          this.form.value.blackOutLogic
        )
        .subscribe(
          (response: any) => {
            this.isLoading = false;

            if (response.status == "success") {
              this.file_select = false;
              const dialogRef = this.matDialog.open(CampaignPaymentComponent, {
                width: "630px",
                height: "630px",
                data: {
                  campaignId: response.campaignId,
                  campaignCost: response.campaignCost,
                  walletBalance: response.walletBalance,
                  chargeableCost: response.chargeableCost,
                  campaignStatus: response.campaignStatus,
                  mobile: response.userMobile,
                  duplicatesRemoved: response.duplicatesRemoved,
                  invalidNumbers: 0,
                },
              });
            } else {
              console.log("invalid");
            }
          },
          (error) => {
            this.isLoading = false;
            console.log(error);
          }
        );
      //console.log(this.form);
    } else if (this.form.value.isContactList == 2) {
      // if (
      //   this.form.value.msisdns.match(/^$|^[7][0-9]{8}([,][7][0-9]{8})*$/) !=
      //   null
      // ) {
      let sampleMsisdns = this.form.value.msisdns.split(",");
      let sampleMobileList = [];
      sampleMsisdns.forEach((mobile) => {
        sampleMobileList.push({
          mobile: mobile,
        });
      });
      if (sampleMobileList.length > 0) {
        this.campaignService
          .createCampaignOnlyForPhoneNumbers(
            this.form.value.sourceAddress,
            this.form.value.campaignName,
            this.form.value.isOptOutRequired ? "1" : "0",
            momentT(this.form.value.scheduleDate)
              .tz("Asia/kolkata")
              .format("YYYY-MM-DD"),
            scheduleNewTime,
            this.form.value.message,
            sampleMobileList,
            this.form.value.scheduleNow ? "1" : "0",
            this.form.value.blackOutLogic
          )
          .subscribe(
            (response: any) => {
              this.isLoading = false;

              if (response.status == "success") {
                this.file_select = false;
                const dialogRef = this.matDialog.open(
                  CampaignPaymentComponent,
                  {
                    width: "630px",
                    height: "630px",
                    data: {
                      campaignId: response.campaignId,
                      campaignCost: response.campaignCost,
                      walletBalance: response.walletBalance,
                      chargeableCost: response.chargeableCost,
                      campaignStatus: response.campaignStatus,
                      mobile: response.userMobile,
                      duplicatesRemoved: response.duplicatesRemoved,
                      invalidNumbers: 0,
                    },
                  }
                );
              } else {
                console.log("invalid");
              }
            },
            (error) => {
              this.isLoading = false;
              console.log(error);
            }
          );
        //console.log(this.form);
      }
      // }
    }
  }

  onNumberClick(event: Event) {
    this.file_select = true;
  }
  onExcelPicked(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ fileSelect: file });
    this.form.get("fileSelect").updateValueAndValidity();
    let fileName = file.name;
    if (fileName.length > 45) {
      fileName = fileName.toString().substr(0, 45) + "...";
    }
    this.form.patchValue({ fileName: fileName });
    this.form.get("fileName").updateValueAndValidity();
  }
  messageCounterUpdate() {
    if (this.form.value.message != null) {
      if (hasUnicode(this.form.value.message)) {
        this.msg_default_size = 70;
        this.setMessageLength();
      } else {
        this.msg_default_size = 160;
        this.setMessageLength();
      }
    } else {
      this.msg_default_size = 160;
      this.setMessageLength();
    }
  }
  //function to send message counter in the UI
  setMessageLength() {
    //+ (this.signatureText.length + 1)
    if (this.account?.account_ussage == 2) {
      //promotional
      // var msg_temp_length =
      //   this.form.value.message.length + this.signatureText.length;
      var msg_temp_length = this.form.value.message.length;
    } else {
      //transaction
      var msg_temp_length = this.form.value.message.length;
    }

    var quotient = Math.floor(msg_temp_length / this.msg_default_size);
    var remainder =
      (msg_temp_length - this.msg_default_size * quotient) %
      this.msg_default_size;
    if (msg_temp_length % this.msg_default_size == 0) {
      if (
        msg_temp_length == this.msg_default_size * quotient &&
        msg_temp_length != 0
      ) {
        this.messageRemLength = 0;
        this.numberOfMessages = quotient;
      } else {
        this.messageRemLength = this.msg_default_size - remainder;
        this.numberOfMessages = quotient;
      }
    } else {
      this.messageRemLength = this.msg_default_size - remainder;
      this.numberOfMessages = quotient + 1;
    }
    //setting number of messages to 1 when ever message box is empty
    if (this.form.value.message.length == 0) {
      this.numberOfMessages = 1;
    }
  }
  openDialog(): void {
    // const dialogRef = this.matDialog.open(CampaignPaymentComponent, {
    //   width: "630px",
    //   height: "630px",
    //   data: {},
    // });
  }
  maskDropDownChange() {
    if (this.account?.account_ussage == 2) {
      this.maskName = this.form.value.sourceAddress;
      this.signatureText =
        " *StopAd? SMS BL " + this.maskName + " to 077XXXXXXX*";
      this.messageCounterUpdate();
    } else {
      //for tranaction no message displaying
      this.maskName = this.form.value.sourceAddress;
      this.signatureText = "";
      this.messageCounterUpdate();
    }
  }
  setTemplateMessage() {
    this.form.patchValue({ message: this.form.get("templateList").value });
    this.form.get("message").updateValueAndValidity();
    this.messageCounterUpdate();
  }
  radionChanged() {
    //number file
    if (this.form.value.isContactList == 0) {
      //remove contactList
      this.form.get("contactList").clearValidators();
      this.form.get("contactList").updateValueAndValidity();

      //remove msisdns
      this.form.get("msisdns").clearValidators();
      this.form.get("msisdns").updateValueAndValidity();
      this.form.get("msisdns").setValidators([
        // Validators.pattern("^[0-9]{9}([,][0-9]{9})*$"),
        Validators.maxLength(60000 * 12),
      ]);
      this.form.get("msisdns").updateValueAndValidity();
      //file upload
      this.form.get("fileSelect").setValidators([Validators.required]);
      this.form.get("fileSelect").setAsyncValidators([NumberMimeType]);
      this.form.get("fileSelect").updateValueAndValidity();
      this.form.get("fileName").setValidators([Validators.required]);
      this.form.get("fileName").updateValueAndValidity();
    }
    //contact list
    else if (this.form.value.isContactList == 1) {
      //remove image upload
      this.form.get("fileSelect").clearValidators();
      this.form.get("fileSelect").clearAsyncValidators();
      this.form.get("fileSelect").updateValueAndValidity();
      this.form.get("fileName").clearValidators();
      this.form.get("fileName").updateValueAndValidity();
      //remove msisdns
      this.form.get("msisdns").clearValidators();
      this.form.get("msisdns").updateValueAndValidity();
      this.form.get("msisdns").setValidators([
        // Validators.pattern("^[0-9]{9}([,][0-9]{9})*$"),
        Validators.maxLength(60000 * 12),
      ]);
      this.form.get("msisdns").updateValueAndValidity();

      this.form
        .get("contactList")
        .setValidators([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9 _`'&-]*$"),
        ]);
      this.form.get("contactList").updateValueAndValidity();
    } else if (this.form.value.isContactList == 2) {
      //remove contactList
      this.form.get("contactList").clearValidators();
      this.form.get("contactList").updateValueAndValidity();
      //remove image upload
      this.form.get("fileSelect").clearValidators();
      this.form.get("fileSelect").clearAsyncValidators();
      this.form.get("fileSelect").updateValueAndValidity();
      this.form.get("fileName").clearValidators();
      this.form.get("fileName").updateValueAndValidity();

      //add required validator
      this.form.get("msisdns").setValidators([
        Validators.required,
        // Validators.pattern("^[0-9]{9}([,][0-9]{9})*$"),
        Validators.maxLength(60000 * 12),
      ]);
      this.form.get("msisdns").updateValueAndValidity();
    }
  }

  replaceSignature(s: string) {
    return s && s.replace("077XXXXXXX", "9010");
  }
}
function hasUnicode(str) {
  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 127) return true;
  }
  return false;
}
function formateString(str) {
  var strArray = str.split(/\r?\n/);
  strArray = strArray.map((item) => {
    // item = item?.toString();
    // if (item?.length === 0) {
    //   item = null;
    // } else if (item?.length == 9) {
    //   item = item;
    // } else if (item?.length <= 11) {
    //   item = item?.replace(/^(\d{0,2})(\d{0,9})/, "$2");
    // } else if (item?.length == 12) {
    //   item = item?.replace(/^(\d{0,3})(\d{0,9})/, "$2");
    // }

    return item;
  });

  return strArray.join(",").split(",,").join(",");
}
@Component({
  selector: "campiagn-payment-popup-dialog",
  templateUrl: "./payment-modal.component.html",
  styleUrls: ["./payment-modal.component.css"],
})
export class CampaignPaymentComponent implements OnInit {
  selectedPaymentOption = null;
  paymentType;

  ngOnInit() {
    this.paymentType = this.authService.getPaymentType();
    if (this.data.campaignId == null) {
      this.closeDialog();
    }
  }
  constructor(
    public dialogRef: MatDialogRef<CampaignPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.router.navigate(["/campaign/view", this.data.campaignId]);
    this.dialogRef.close();
  }
  walletOption() {
    this.selectedPaymentOption = 0;
  }
  ezCashOption() {
    this.selectedPaymentOption = 1;
  }
  addToBillOption() {
    this.selectedPaymentOption = 2;
  }
  packageOption() {
    this.selectedPaymentOption = 4;
  }
  initiatePayment() {
    // console.log(this.selectedPaymentOption);
    if (
      this.selectedPaymentOption == null ||
      (this.selectedPaymentOption != 0 &&
        this.selectedPaymentOption != 1 &&
        this.selectedPaymentOption != 2 &&
        this.selectedPaymentOption != 4)
    ) {
      return;
    }

    //0 stands for campaign payment
    let type = 0;
    //campign value is handled in backend
    //setting defualt value to 0
    let default_val = 0;
    this.campaignService
      .initiatePayment(
        +this.data.campaignId,
        +this.selectedPaymentOption,
        +type,
        +default_val
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          this.closeDialog();
          this.router.navigate(["/campaign/view", this.data.campaignId]);
          //how capture success response
          this.matDialog.open(SuccessDialogBoxComponent, {
            width: "532px",
            data: { message: response.comment },
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }
}

@Directive({
  selector: "[appBlockCopyPaste]",
})
export class BlockCopyPasteDirective {
  constructor() {}

  @HostListener("paste", ["$event"]) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener("copy", ["$event"]) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener("cut", ["$event"]) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
