<app-campaign-secondary-header></app-campaign-secondary-header>
<section class="pt-5 e-body-wrapper">
  <div class="container">
    <div class="row">
      <div class="col-12 e-card e-card--no-paddings">
        <mat-tab-group (selectedTabChange)="tabChange($event)" #tabGroup>
          <mat-tab label="ALL">
            <app-campaigns-all *ngIf="campaignType=='ALL'" [campaignType]="campaignType"></app-campaigns-all>
          </mat-tab>
          <mat-tab label="PENDING">
            <app-campaigns-all *ngIf="campaignType==0" [campaignType]="campaignType"></app-campaigns-all>
          </mat-tab>
          <mat-tab label="RUNNING">
            <app-campaigns-all *ngIf="campaignType==1" [campaignType]="campaignType"></app-campaigns-all>
          </mat-tab>
          <mat-tab label="COMPLETED">
            <app-campaigns-all *ngIf="campaignType==2" [campaignType]="campaignType"></app-campaigns-all>
          </mat-tab>
          <mat-tab label="DRAFT">
            <app-campaigns-all *ngIf="campaignType=='DRAFT'" [campaignType]="campaignType"></app-campaigns-all>
          </mat-tab>
          <mat-tab label="PAUSE BLACKOUT">
            <app-campaigns-all *ngIf="campaignType==16" [campaignType]="campaignType"></app-campaigns-all>
          </mat-tab>
          <mat-tab label="STOP BLACKOUT">
            <app-campaigns-all *ngIf="campaignType==17" [campaignType]="campaignType"></app-campaigns-all>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</section>
