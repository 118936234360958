import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { interval, BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class VersionCheckService {
  private currentVersion = "";
  private versionUrl = "/assets/version.json"; // Path to version.json

  constructor(private http: HttpClient) {}

  private getVersionUrl(): string {
    return `${this.versionUrl}?t=${new Date().getTime()}`;
  }

  // Method to start version checking
  startVersionCheck(intervalMs: number): void {
    interval(intervalMs)
      .pipe(
        switchMap(() =>
          this.http.get<{ version: string }>(this.getVersionUrl())
        )
      )
      .subscribe({
        next: (response) => this.checkVersion(response.version),
        error: (err) => console.error("Version check failed:", err),
      });
  }

  // Compare versions and reload if a new version is detected
  private checkVersion(newVersion: string): void {
    if (!this.currentVersion) {
      this.currentVersion = newVersion; // Set initial version
    } else if (this.currentVersion !== newVersion) {
      console.log("New version detected. Reloading...");
      this.reloadApp();
    }
  }

  // Reload the application
  private reloadApp(): void {
    window.location.reload();
  }
}
