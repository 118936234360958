import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { AuthService } from "src/app/auth/auth.service";
import { CampaignService } from "../../campaign.service";
import * as moment from "moment";
import * as momentT from "moment-timezone";
import * as XLSX from "xlsx";
import { MatTableDataSource } from "@angular/material/table";
import { MatOption } from "@angular/material/core";

export interface DisplayedColumns {
  key: string;
  value: string;
}

@Component({
  selector: "app-user-enterprise-dashboard",
  templateUrl: "./user-enterprise-dashboard.component.html",
  styleUrls: ["./user-enterprise-dashboard.component.css"],
})
export class UserEnterpriseDashboardComponent implements OnInit {
  data = [];
  isLoading = false;
  isRefresh = false;
  isExportingExcel = false;

  totalPostsTwo = 0;
  postsPerPageTwo = 5;
  pageSizeOptionTwo = [5, 10, 25, 100];
  currentPageTwo = 1;

  dataSourceTwo;
  displayedColumnsTwo: DisplayedColumns[] = [
    { key: "0", value: "All" },
    { key: "campaign_id", value: "Campaign ID" },
    { key: "campaign_name", value: "Campaign Name" },
    { key: "scheduled_date", value: "Scheduled Date" },
    { key: "status", value: "Status" },
    { key: "campaign_type", value: "Campaign Type" },
    { key: "segments", value: "Segments" },
    { key: "total_number_base", value: "Total Number Base" },
    { key: "total_msgs_count", value: "Total Messages Count" },
    { key: "dialog_number_base", value: "Dialog Number Base" },
    { key: "dialog_msgs_count", value: "Dialog Messages Count" },
    { key: "non_dialog_number_base", value: "Non Dialog Number Base" },
    { key: "non_dialog_msgs_count", value: "Non Dialog Messages Count" },
    // { key: "estimated_budget", value: "Estimated Budget (+ Tax)" },
    // {
    //   key: "estimated_dialog_budget",
    //   value: "Estimated Dialog Budget (+ Tax)",
    // },
    // {
    //   key: "estimated_non_dialog_budget",
    //   value: "Estimated Non Dialog Budget (+ Tax)",
    // },
    { key: "total_delivery_base", value: "Total Delivery Base" },
    // { key: "total_delivery_count", value: "Total Delivery Count" },
    { key: "dialog_delivery_base", value: "Dialog Delivery Base" },
    // { key: "dialog_delivery_count", value: "Dialog Delivery Count" },
    { key: "non_dialog_delivery_base", value: "Non Dialog Delivery Base" },
    // { key: "non_dialog_delivery_count", value: "Non Dialog Delivery Count" },
    // { key: "campaign_cost", value: "Campaign Cost" },
    // { key: "dialog_cost", value: "Dialog Cost" },
    // { key: "non_dialog_cost", value: "Non Dialog Cost" },
    // { key: "tax", value: "Tax" },
    { key: "total_campaign_cost", value: "Total Campaign Cost" },
  ];
  columnsToDisplayTwo: string[] = this.displayedColumnsTwo
    .map(function (item) {
      return item.key;
    })
    .filter((el) => el != "0")
    .slice();
  @ViewChild("TableTwoPaginator", { static: false })
  tableTwoPaginator: MatPaginator;
  @ViewChild("TableTwoSort") tableTwoSort: MatSort;

  form;
  fromDate = "_ _ _ _";
  toDate = "_ _ _ _";
  @ViewChild("DateWiseTable") tableDate: ElementRef;
  @ViewChild("CampaignWiseTable") tableCampaign: ElementRef;

  filterColumns = new FormControl(this.columnsToDisplayTwo);
  constructor(
    private authService: AuthService,
    private textCapaignService: CampaignService
  ) {
    this.dataSourceTwo = new MatTableDataSource(null);
  }
  ngOnInit() {
    this.filterColumns.patchValue([...this.columnsToDisplayTwo, "0"]);
    this.form = new FormGroup({
      fromDate: new FormControl(null, {
        validators: [Validators.required],
      }),
      toDate: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
  }

  toggleColumnSelection(event: MatOption) {
    var allColumns = this.displayedColumnsTwo
      .map(function (item) {
        return item.key;
      })
      .filter((el) => el != "0")
      .slice();
    if (event.value == "0" && event.selected) {
      this.filterColumns.patchValue([
        ...this.displayedColumnsTwo.map((item) => item.key),
        "0",
      ]);
      this.columnsToDisplayTwo = this.displayedColumnsTwo
        .map(function (item) {
          return item.key;
        })
        .filter((el) => el != "0")
        .slice();
    } else if (event.value == "0" && !event.selected) {
      this.filterColumns.patchValue([]);
      this.columnsToDisplayTwo = [].slice();
    } else if (
      event.value != "0" &&
      event.selected &&
      allColumns.length == this.filterColumns.value.length
    ) {
      this.columnsToDisplayTwo = this.filterColumns.value
        .filter((el) => el != "0")
        .slice();
      this.filterColumns.patchValue([...this.columnsToDisplayTwo, "0"]);
    } else {
      this.columnsToDisplayTwo = this.filterColumns.value
        .filter((el) => el != "0")
        .slice();
      this.filterColumns.patchValue([...this.columnsToDisplayTwo]);
    }
  }

  public dateFilterFrom = (d: Date): boolean => {
    const value = this.form.value;
    if (value.toDate) {
      return true && d <= this.convertToDate(value.toDate);
    } else {
      return true;
    }
  };

  public dateFilterTo = (d: Date): boolean => {
    const value = this.form.value;
    if (value.fromDate) {
      return d >= this.convertToDate(value.fromDate) && true;
    } else {
      return true;
    }
  };

  protected convertToDate(d: Date | string): Date {
    return typeof d === "string" ? new Date(d) : d;
  }

  applySearch(formDirective: FormGroupDirective) {
    // console.log(this.form);
    if (this.form.invalid) {
      return;
    }

    this.isRefresh = true;

    this.fromDate = momentT(this.form.value.fromDate)
      .tz("Asia/kolkata")
      .format("YYYY-MM-DD");
    this.toDate = momentT(this.form.value.toDate)
      .tz("Asia/kolkata")
      .format("YYYY-MM-DD");

    this.postsPerPageTwo = 5;
    this.pageSizeOptionTwo = [5, 10, 25, 100];
    this.currentPageTwo = 1;

    this.fetchDataTwo(
      this.currentPageTwo,
      this.postsPerPageTwo,
      this.fromDate,
      this.toDate
    );
  }

  fetchDataTwo(currentPage, postsPerPage, fromDate, toDate) {
    this.isLoading = true;
    this.dataSourceTwo = new MatTableDataSource(null);
    //get campaign list

    this.authService
      .getReportUsageEnterpriseDashboard(
        currentPage,
        postsPerPage.toString(),
        fromDate,
        toDate
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          // this.totalPostsTwo = response.data.count;
          this.data =
            response?.data?.data?.map((a) => ({
              campaign_id: a.id,
              campaign_name: a.campaign_name,
              scheduled_date: momentT(a.schedule_time)
                .tz("Etc/UTC")
                .format("YYYY-MM-DD HH:mm:ss"),
              status: this.fetchedCampaignStatus(a.status),
              campaign_type: this.fetchCampaignTypes(a.campaign_type),
              segments: a.segments,
              total_number_base: a.total_number_base,
              total_msgs_count: a.total_msg_count,
              dialog_number_base: a.dialog_number_base,
              dialog_msgs_count: a.dialog__msg_count,
              non_dialog_number_base: a.non_dialog_number_base,
              non_dialog_msgs_count: a.non_dialog__msg_counts,
              // estimated_budget: a.estimated_budget?.toFixed(2),
              // estimated_dialog_budget: a.estimated_dialog_budget?.toFixed(2),
              // estimated_non_dialog_budget:
              //   a.estimated_non_dialog_budget?.toFixed(2),
              total_delivery_base: a.total_delivery_base,
              // total_delivery_count: a.total_delivery_count,
              dialog_delivery_base: a.dialog_delivery_base,
              // dialog_delivery_count: a.dialog_delivery_count,
              non_dialog_delivery_base: a.non_dialog_delivery_base,
              // non_dialog_delivery_count: a.non_dialog_delivery_count,
              // campaign_cost: null,
              // dialog_cost: a.dialog_cost?.toFixed(2),
              // non_dialog_cost: a.non_dialog_cost?.toFixed(2),
              // tax: null,
              total_campaign_cost: Number(a.total_campaign_cost)?.toFixed(2),
            })) || [];
          ////////console.log(this.totalPosts);
          this.dataSourceTwo = new MatTableDataSource(this.data);
          this.dataSourceTwo.sort = this.tableTwoSort;
          if (this.data.length < this.postsPerPageTwo) {
            this.totalPostsTwo = this.currentPageTwo * this.postsPerPageTwo;
          } else {
            this.totalPostsTwo = this.currentPageTwo * this.postsPerPageTwo + 1;
          }
          this.isLoading = false;
          this.isRefresh = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isRefresh = false;
        }
      );
  }

  onChangedPageTwo(pageData: PageEvent) {
    // console.log(pageData.pageIndex, pageData.pageSize)
    this.currentPageTwo = pageData.pageIndex + 1;
    this.postsPerPageTwo = pageData.pageSize;
    this.fetchDataTwo(
      this.currentPageTwo,
      this.postsPerPageTwo,
      this.fromDate,
      this.toDate
    );
  }

  ExportTOExcel() {
    this.isExportingExcel = true;
    this.authService
      .getReportUsageEnterpriseDashboard("1", "all", this.fromDate, this.toDate)
      .subscribe(
        (response: any) => {
          //console.log(response);
          var data =
            response?.data?.data?.map((a) => {
              var row = {
                campaign_id: a.id,
                campaign_name: a.campaign_name,
                scheduled_date: momentT(a.schedule_time)
                  .tz("Etc/UTC")
                  .format("YYYY-MM-DD HH:mm:ss"),
                status: this.fetchedCampaignStatus(a.status),
                campaign_type: this.fetchCampaignTypes(a.campaign_type),
                segments: Number(a.segments),
                total_number_base: Number(a.total_number_base),
                total_msgs_count: Number(a.total_msg_count),
                dialog_number_base: Number(a.dialog_number_base),
                dialog_msgs_count: Number(a.dialog__msg_count),
                non_dialog_number_base: a.non_dialog_number_base,
                non_dialog_msgs_count: Number(a.non_dialog__msg_counts),
                // estimated_budget: a.estimated_budget.toFixed(2),
                // estimated_dialog_budget: a.estimated_dialog_budget.toFixed(2),
                // estimated_non_dialog_budget:
                //   a.estimated_non_dialog_budget.toFixed(2),
                total_delivery_base: Number(a.total_delivery_base),
                // total_delivery_count: a.total_delivery_count,
                dialog_delivery_base: Number(a.dialog_delivery_base),
                // dialog_delivery_count: a.dialog_delivery_count,
                non_dialog_delivery_base: Number(a.non_dialog_delivery_base),
                // non_dialog_delivery_count: a.non_dialog_delivery_count,
                // campaign_cost: null,
                // dialog_cost: a.dialog_cost.toFixed(2),
                // non_dialog_cost: a.non_dialog_cost.toFixed(2),
                // tax: null,
                total_campaign_cost: Number(
                  Number(a.total_campaign_cost)?.toFixed(2)
                ),
              };

              return this.columnsToDisplayTwo.reduce(
                (a, key) => Object.assign(a, { [key]: row[key] }),
                {}
              );
            }) || [];
          var dataSourceOne = new MatTableDataSource(data);

          const Heading = [this.columnsToDisplayTwo];

          /* add the header */
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_aoa(ws, Heading);

          //Starting in the second row to avoid overriding and skipping headers
          XLSX.utils.sheet_add_json(ws, dataSourceOne.filteredData, {
            origin: "A2",
            skipHeader: true,
          });

          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          /* save to file */
          XLSX.writeFile(
            wb,
            "report-usage-enterprise" + new Date().getTime() + ".xlsx"
          );
          this.isExportingExcel = false;
        },
        (error) => {
          console.log(error);
          this.isExportingExcel = false;
        }
      );
  }

  fetchedCampaignStatus(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "Pending";
        break;
      case 1:
        status_string = "Running";
        break;
      case 2:
        status_string = "Completed";
        break;
      case 3:
        status_string = "Deleted ";
        break;
      case 4:
        status_string = "Modified";
        break;
      case 5:
        status_string = "Paused";
        break;
      case 6:
        status_string = "Expired";
        break;
      case 7:
        status_string = "Two phase locked ";
        break;
      case 8:
        status_string = "Stopped";
        break;
      case 9:
        status_string = "Waiting for payment";
        break;
      case 11:
        status_string = "Payment failed";
        break;
      case 12:
        status_string = "Waiting for EzCash";
        break;
      case 14:
        status_string = "Admin review pending";
        break;
      case 15:
        status_string = "Admin review rejected";
        break;
      case 16:
        status_string = "On pause after reaching blackout";
        break;
      case 17:
        status_string = "Stopped after reaching blackout";
        break;
      default:
        status_string = "Inactive";
        break;
    }
    return status_string;
  }

  fetchCampaignTypes(type) {
    let type_string = null;
    switch (type) {
      case 0:
        type_string = "SMS normal";
        break;
      case 1:
        type_string = "SMS unicode";
        break;
      case 2:
        type_string = "Rich Message";
        break;
      case 3:
        type_string = "Personalized Message";
        break;
      case 4:
        type_string = "Personalized Promotional Message";
        break;
      case 5:
        type_string = "Promotional Normal Message";
        break;
      case 6:
        type_string = "Promotional Unicode Message";
        break;
      default:
        type_string = "Invalid";
        break;
    }
    return type_string;
  }
}
