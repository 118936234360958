import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CampaignService } from "src/app/campaign/campaign.service";

@Component({
  selector: "app-template-list-all",
  templateUrl: "./template-list-all.component.html",
  styleUrls: ["./template-list-all.component.css"],
})
export class TemplateListAllComponent implements OnInit {
  isLoading = true;

  dataSourceAll;

  totalPosts = 0;
  postsPerPage = 5;
  pageSizeOption = [5, 10, 25, 100];
  currentPage = 1;

  limit = 40;

  dataSourceOne;
  displayedColumnsOne: string[] = [
    "id",
    "template_name",
    "message",
    "createdAt",
    "updatedAt",
    "creator_id",
  ];
  @ViewChild("TableOnePaginator", { static: false })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort") tableOneSort: MatSort;

  constructor(private textCapaignService: CampaignService) {}

  ngOnInit(): void {
    this.fetchData(this.currentPage, this.postsPerPage);
  }

  fetchData(currentPage, postsPerPage) {
    this.isLoading = true;
    this.dataSourceOne = new MatTableDataSource(null);
    //get campaign list

    this.textCapaignService
      .getTemplateList(currentPage.toString(), postsPerPage.toString())
      .subscribe(
        (response: any) => {
          // console.log(response);
          // this.totalPosts = response.data.count;
          ////////console.log(this.totalPosts);
          this.dataSourceAll = response?.data?.data||[];
          this.dataSourceOne = new MatTableDataSource(this.dataSourceAll);
          this.dataSourceOne.sort = this.tableOneSort;
          if (this.dataSourceAll.length < this.postsPerPage) {
            this.totalPosts = this.currentPage * this.postsPerPage;
          } else {
            this.totalPosts = this.currentPage * this.postsPerPage + 1;
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
  }

  onChangedPage(pageData: PageEvent) {
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchData(this.currentPage, this.postsPerPage);
  }

  formatContent(content: string) {
    if (content.substr(0, this.limit) == content) {
      return content;
    } else {
      return `${content.substr(
        0,
        this.limit
      )}<font >...</font>`;
    }
  }

  fetchedTemplateStatus(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "Pending";
        break;
      case 1:
        status_string = "Running";
        break;
      case 2:
        status_string = "Completed";
        break;
      case 3:
        status_string = "Deleted ";
        break;
      case 4:
        status_string = "Modified";
        break;
      case 5:
        status_string = "Paused";
        break;
      case 6:
        status_string = "Expired";
        break;
      case 7:
        status_string = "Two phase locked ";
        break;
      case 8:
        status_string = "Stopped";
        break;
      case 9:
        status_string = "Waiting for payment";
        break;
      case 11:
        status_string = "Payment failed";
        break;
      case 12:
        status_string = "Waiting for EzCash";
        break;
      case 14:
        status_string = "Admin review pending";
        break;
      case 15:
        status_string = "Admin review rejected";
        break;
      case 16:
        status_string = "On pause after reaching blackout";
        break;
      case 17:
        status_string = "Stopped after reaching blackout";
        break;
      default:
        status_string = "Inactive";
        break;
    }
    return status_string;
  }
}
