<section class="e-body-wrapper pb-5">
  <div class="container e-body-wrapper">
    <div class="row">
      <div class="col-12 pt-4 pb-2">
        <button mat-button class="e-btn e-back-btn" routerLink="/campaign/list">
          <span class="material-icons e-back-btn__icon"> arrow_back </span>BACK
        </button>
      </div>
      <div class="col-12" style="width: 100%; display: flex; justify-content: center">
        <div class="col-md-12 e-card">
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <form *ngIf="!isLoading" (submit)="createCampaign()" [formGroup]="form" #campaignFormDirective="ngForm">
            <h4 class="mb-4 e-page-title text-center">Create campaign</h4>
            <div class="row">
              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="e-select">
                  <mat-label>Mask</mat-label>
                  <mat-select matNativeControl formControlName="sourceAddress" (selectionChange)="maskDropDownChange()">
                    <mat-option *ngFor="let mask of masks" [value]="mask.value">
                      {{ mask.viewValue }}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="
                      form.get('sourceAddress').hasError('pattern') ||
                      form.get('sourceAddress').hasError('required')
                    ">Please select a valid mask</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Campaign name</mat-label>
                  <input matInput type="text" formControlName="campaignName" maxlength="44" />
                  <mat-error *ngIf="form.get('campaignName').hasError('required')">Campaign name can't be empty
                  </mat-error>
                  <mat-error *ngIf="form.get('campaignName').hasError('minlength')">Campaign name contain atleast 3
                    characters</mat-error>
                  <mat-error *ngIf="form.get('campaignName').hasError('pattern')">Illegal characters in the Campaign
                    name</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Phone numbers</mat-label>
                  <textarea matInput type="text" placeholder="Comma seperated 9 digit numbers. Ex:76######1,77#####2"
                    formControlName="msisdns" (paste)="onPasteMobileNumbers($event)" rows="3" wrap="hard"
                    appBlockCopyPaste></textarea>
                  <mat-hint>Ex: 7XXXXXXXX, 7XXXXXXXX</mat-hint>
                  <mat-error *ngIf="form.get('msisdns').hasError('required')">Numbers can't be empty
                  </mat-error>
                  <!-- <mat-error *ngIf="form.get('msisdns').hasError('pattern')">9 Digit Numbers Only.Use comma to seperate
                    numbers</mat-error> -->
                  <mat-error *ngIf="form.get('msisdns').hasError('maxlength')">Maximum length are exceed.
                  </mat-error>
                </mat-form-field>
                <!-- <small class="e-input">{{ '40,000' }} / {{ getNumbersCount() }}</small> -->
              </div>
              <div class="col-md-6 col-12">
                <mat-label></mat-label><br />
                <mat-radio-group aria-label="yes_no" formControlName="isContactList" class="e-radio"
                  (change)="radionChanged()">
                  <mat-radio-button value="2">Send only to Phone numbers</mat-radio-button>&nbsp;
                  <mat-radio-button value="0">Upload Number File</mat-radio-button>&nbsp;
                  <mat-radio-button value="1">Select Contact List</mat-radio-button>

                  <mat-error *ngIf="
                      form.get('isContactList').hasError('required') ||
                      form.get('isContactList').hasError('minlength') ||
                      form.get('isContactList').hasError('maxLength') ||
                      form.get('isContactList').hasError('pattern')
                    ">Invalid Value</mat-error>
                </mat-radio-group>
                <mat-form-field appearance="outline" class="e-f-inp" *ngIf="this.form.value.isContactList == 0">
                  <mat-label>Number file</mat-label>
                  <input matInput type="text" name="" readonly formControlName="fileName" class="e-f-inp" />
                  <mat-hint *ngIf="
                      (!form.get('fileSelect').valid && this.file_select) ||
                      (!form.get('fileName').valid &&
                        form.get('fileName').touched)
                    " style="color: red">Not a csv or excel and maximum file size is 12MB</mat-hint>
                  <mat-hint *ngIf="
                      !(!form.get('fileSelect').valid && this.file_select) &&
                      !(
                        !form.get('fileName').valid &&
                        form.get('fileName').touched
                      )
                    ">Only supports CSV and Excel</mat-hint>
                  <button mat-button type="button" (click)="filePicker.click()" class="e-f-inp__select">
                    UPLOAD
                  </button>

                  <input type="file" style="display: none" #filePicker (change)="onExcelPicked($event)" />
                </mat-form-field>
                <mat-form-field appearance="outline" class="e-select" *ngIf="this.form.value.isContactList == 1">
                  <mat-label>Contact List</mat-label>
                  <mat-select matNativeControl formControlName="contactList" (selectionChange)="maskDropDownChange()">
                    <mat-option *ngFor="let item of this.contactList" [value]="item.id">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="
                      form.get('contactList').hasError('pattern') ||
                      form.get('contactList').hasError('required')
                    ">Please select a valid Contact List</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" (click)="date_picker.open()">
                  <mat-label>Schedule Date</mat-label>
                  <input matInput [matDatepicker]="date_picker" maxlength="10" minlength="8"
                    formControlName="scheduleDate" readonly [min]="minDate" [max]="maxDate" />

                  <mat-datepicker-toggle matSuffix [for]="date_picker"></mat-datepicker-toggle>
                  <mat-datepicker #date_picker></mat-datepicker>

                  <mat-error *ngIf="
                      form.get('scheduleDate').hasError('minLength') ||
                      form.get('scheduleDate').hasError('maxLength') ||
                      form.get('scheduleDate').hasError('required')
                    ">Invalid Date</mat-error>
                  <mat-error *ngIf="
                      form.get('scheduleDate').hasError('matDatepickerMin') ||
                      form.get('scheduleDate').hasError('matDatepickerMax')
                    ">
                    Date is in past or not with in 7 days from today
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- min="08:00 am"
                  max="08:00 pm" -->
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12">
                    <mat-form-field appearance="outline" class="e-time-inp">
                      <mat-label>Schedule Time</mat-label>
                      <input matInput type="text" [ngxTimepicker]="sch_time_picker" readonly #scheduleTimeUI
                        formControlName="scheduleTime" />
                      <ngx-material-timepicker #sch_time_picker [enableKeyboardInput]="true"></ngx-material-timepicker>
                      <mat-icon (click)="scheduleTimeUI.click()" matSuffix>access_time</mat-icon>
                      <mat-error *ngIf="
                          form.get('scheduleTime').hasError('minLength') ||
                          form.get('scheduleTime').hasError('maxLength') ||
                          form.get('scheduleTime').hasError('required') ||
                          form.get('scheduleTime').hasError('pattern')
                        ">Invalid Time</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-12 e-cb-wrapper mb-4">
                    <mat-checkbox class="e-cb-wrapper__cb" formControlName="scheduleNow" (change)="scheduleNowChange()">
                      Schedule Now!</mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="e-select">
                  <mat-label>Template List</mat-label>
                  <mat-select matNativeControl formControlName="templateList" (selectionChange)="setTemplateMessage()">
                    <mat-option *ngFor="let item of this.templateList" [value]="item.message">
                      {{ item.template_name }}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="
                      form.get('templateList').hasError('pattern') ||
                      form.get('templateList').hasError('required')
                    ">Please select a valid Template List</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12" *ngIf="this.account?.account_ussage == 2">
                <mat-form-field appearance="outline" class="e-select">
                  <mat-label>Black Out Type</mat-label>
                  <mat-select matNativeControl formControlName="blackOutLogic">
                    <mat-option [value]="1" matTooltip="Pause the campaign during the blackout period and refrain from resuming it after the blackout
                    period ends" matTooltipPosition="above">
                      Pause the campaign during the blackout period and refrain from resuming it after the blackout
                      period ends
                    </mat-option>
                    <mat-option [value]="2"
                      matTooltip="Pause the campaign during the blackout period and resume it after the blackout period ends"
                      matTooltipPosition="above">
                      Pause the campaign during the blackout period and resume it after the blackout period ends
                    </mat-option>
                    <mat-option [value]="3" matTooltip="Disregard the blackout period and continue with the campaign"
                      matTooltipPosition="above" *ngIf="this.account?.isAllowedToPermitThroughBlackOutTime">
                      Disregard the blackout period and continue with the campaign
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="
                      form.get('blackOutLogic').hasError('required')
                    ">Please select a valid Option</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Message</mat-label>
                  <textarea matInput formControlName="message" (input)="messageCounterUpdate()">
                  </textarea>
                  <br />
                  <p class="e-input" readonly style="border: none"
                    *ngIf="this.account?.account_ussage == 2 || form.get('isOptOutRequired').value">
                    {{ replaceSignature(this.signatureText) }}
                  </p>
                  <mat-hint>{{ messageRemLength }} / {{ numberOfMessages }}</mat-hint>
                  <mat-error *ngIf="form.get('message').hasError('required')">Message body can't be empty</mat-error>
                  <mat-error *ngIf="form.get('message').hasError('minlength')">Message body must contain atleast 3
                    characters</mat-error>
                  <mat-error *ngIf="form.get('message').hasError('pattern')">Illegal characters in the message body
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row mt-2" *ngIf="this.account?.account_ussage == 1">
              <div class="col-md-6 col-12 e-cb-wrapper">
                <mat-checkbox class="e-cb-wrapper__cb" formControlName="isOptOutRequired">
                  Is OPT OUT required?</mat-checkbox>
              </div>
            </div>
            <div class="row e-btn-group e-btn-group--center mt-2">
              <button mat-stroked-button class="e-btn-stroke mr-2" type="button" [routerLink]="['/campaign/list']">
                CANCEL
              </button>
              <button mat-flat-button color="primary" class="e-btn ml-2" type="submit">
                CREATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
