import { Component, OnInit } from "@angular/core";
import { AuthService } from "./auth/auth.service";
import { VersionCheckService } from "./auth/version-check.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  title = "e-sms";

  constructor(
    private authService: AuthService,
    private versionCheckService: VersionCheckService
  ) {}
  ngOnInit() {
    this.authService.autoAuthUser();
    this.versionCheckService.startVersionCheck(300000);
  }
}
