<section class="e-body-wrapper pb-5">
  <div class="container">
    <div class="row">
      <div class="col-12 pt-4 pb-2">
        <button mat-button class="e-btn e-back-btn" routerLink="/campaign/list">
          <span class="material-icons e-back-btn__icon"> arrow_back </span>BACK
        </button>
      </div>
      <div class="col-12" style="width: 100%; display: flex; justify-content: center">
        <div class="col-md-12 e-card">
          <mat-spinner *ngIf="isLoading"></mat-spinner>
          <form *ngIf="!isLoading" [formGroup]="form" #campaignFormDirective="ngForm">
            <h4 class="mb-4 e-page-title text-center">Create campaign</h4>
            <div class="row">
              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="e-select">
                  <mat-label>Mask</mat-label>
                  <mat-select matNativeControl formControlName="sourceAddress" (selectionChange)="maskDropDownChange()">
                    <mat-option *ngFor="let mask of masks" [value]="mask.value">
                      {{ mask.viewValue }}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="
                      form.get('sourceAddress').hasError('pattern') ||
                      form.get('sourceAddress').hasError('required')
                    ">Please select a valid mask</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="e-input">
                  <mat-label>Campaign name</mat-label>
                  <input matInput type="text" formControlName="campaignName" maxlength="44" />
                  <mat-error *ngIf="form.get('campaignName').hasError('required')">Campaign name can't be empty
                  </mat-error>
                  <mat-error *ngIf="form.get('campaignName').hasError('minlength')">Campaign name contain atleast 3
                    characters</mat-error>
                  <mat-error *ngIf="form.get('campaignName').hasError('pattern')">Illegal characters in the Campaign
                    name</mat-error>
                </mat-form-field>
              </div>
            </div>
            <hr>
            <div class="pt-4 pb-5 row">
              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="e-f-inp">
                  <mat-label>File with corresponding content</mat-label>
                  <input matInput type="text" name="" readonly formControlName="fileName" class="e-f-inp" />
                  <mat-hint *ngIf="
                      (!form.get('fileSelect').valid && this.file_select) ||
                      (!form.get('fileName').valid &&
                        form.get('fileName').touched)
                    " style="color: red">Not a csv or excel and maximum file size is 2MB</mat-hint>
                  <mat-hint *ngIf="
                      !(!form.get('fileSelect').valid && this.file_select) &&
                      !(
                        !form.get('fileName').valid &&
                        form.get('fileName').touched
                      )
                    ">Only supports CSV and Excel<br><br>(Maximum of six columns including mobile number column)<br>
                  </mat-hint>
                  <button mat-button type="button" (click)="filePicker.click()" class="e-f-inp__select">
                    UPLOAD
                  </button>

                  <input type="file" style="display: none" #filePicker (change)="onExcelPicked($event)" />
                </mat-form-field>
              </div>
              <div class="col-md-2 col-12 d-flex align-items-center justify-content-center">
                <a mat-stroked-button color="primary"
                  href="{{hostIpWithPort + '/api/sample-files/sample_for_templated_message.xlsx'}}"
                  style="text-decoration: none;"><img src="../../../../assets/images/Microsoft_Excel-Logo.wine.png"
                    width="40" />Sample Excel</a>
              </div>
              <div class="col-md-2 col-12 d-flex align-items-center justify-content-center">
                <a mat-stroked-button color="primary"
                  href="{{hostIpWithPort + '/api/sample-files/sample_for_templated_message.csv'}}"
                  style="text-decoration: none;"><img src="../../../../assets/images/csv_icon.svg.png"
                    width="40" />Sample CSV</a>
              </div>
            </div>
            <hr>
            <div class="pt-4 row">
              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" (click)="date_picker.open()">
                  <mat-label>Schedule Date</mat-label>
                  <input matInput [matDatepicker]="date_picker" maxlength="10" minlength="8"
                    formControlName="scheduleDate" readonly [min]="minDate" [max]="maxDate" />

                  <mat-datepicker-toggle matSuffix [for]="date_picker"></mat-datepicker-toggle>
                  <mat-datepicker #date_picker></mat-datepicker>

                  <mat-error *ngIf="
                      form.get('scheduleDate').hasError('minLength') ||
                      form.get('scheduleDate').hasError('maxLength') ||
                      form.get('scheduleDate').hasError('required')
                    ">Invalid Date</mat-error>
                  <mat-error *ngIf="
                      form.get('scheduleDate').hasError('matDatepickerMin') ||
                      form.get('scheduleDate').hasError('matDatepickerMax')
                    ">
                    Date is in past or not with in 7 days from today
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- min="08:00 am"
                  max="08:00 pm" -->
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12">
                    <mat-form-field appearance="outline" class="e-time-inp">
                      <mat-label>Schedule Time</mat-label>
                      <input matInput type="text" [ngxTimepicker]="sch_time_picker" readonly #scheduleTimeUI
                        formControlName="scheduleTime" />
                      <ngx-material-timepicker #sch_time_picker [enableKeyboardInput]="true"></ngx-material-timepicker>
                      <mat-icon (click)="scheduleTimeUI.click()" matSuffix>access_time</mat-icon>
                      <mat-error *ngIf="
                          form.get('scheduleTime').hasError('minLength') ||
                          form.get('scheduleTime').hasError('maxLength') ||
                          form.get('scheduleTime').hasError('required') ||
                          form.get('scheduleTime').hasError('pattern')
                        ">Invalid Time</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-12 e-cb-wrapper mb-4">
                    <mat-checkbox class="e-cb-wrapper__cb" formControlName="scheduleNow" (change)="scheduleNowChange()">
                      Schedule Now!</mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                <h4 class="pt-4 pb-2 e-page-title mb-5">Message </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <mat-form-field appearance="outline" class="e-select">
                  <mat-label>Template List</mat-label>
                  <mat-select matNativeControl formControlName="templateList" (selectionChange)="setTemplateMessage()">
                    <mat-option *ngFor="let item of this.templateList" [value]="item.message">
                      {{ item.template_name }}
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="
                        form.get('templateList').hasError('pattern') ||
                        form.get('templateList').hasError('required')
                      ">Please select a valid Template List</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 col-12" *ngIf="this.account?.account_ussage == 2">
                <mat-form-field appearance="outline" class="e-select">
                  <mat-label>Black Out Type</mat-label>
                  <mat-select matNativeControl formControlName="blackOutLogic">
                    <mat-option [value]="1" matTooltip="Pause the campaign during the blackout period and refrain from resuming it after the blackout
                    period ends" matTooltipPosition="above">
                      Pause the campaign during the blackout period and refrain from resuming it after the blackout
                      period ends
                    </mat-option>
                    <mat-option [value]="2"
                      matTooltip="Pause the campaign during the blackout period and resume it after the blackout period ends"
                      matTooltipPosition="above">
                      Pause the campaign during the blackout period and resume it after the blackout period ends
                    </mat-option>
                    <mat-option [value]="3" matTooltip="Disregard the blackout period and continue with the campaign"
                      matTooltipPosition="above" *ngIf="this.account?.isAllowedToPermitThroughBlackOutTime">
                      Disregard the blackout period and continue with the campaign
                    </mat-option>
                  </mat-select>

                  <mat-error *ngIf="
                      form.get('blackOutLogic').hasError('required')
                    ">Please select a valid Option</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10 col-12 mb-5 d-flex justify-content-center">
                <div class="text-area pt-3 pb-5 pl-3" #textArea
                  [ngClass]="{'text-area-error': emptyMessage || keyValueUsed}" contenteditable="true"
                  placeholder="Message" id="editor" (input)="onNameChange()" (click)="onNameChange()"
                  (paste)="pasteEvent($event)">
                  <!-- Please type something in here -->
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="row m-1">
                  <button *ngIf="!btn2" id="btn_add_col2" mat-flat-button color="primary" class="col-12 e-btn-column"
                    (click)="insertImage2()">Add Column
                    2</button>
                  <button *ngIf="btn2" id="btn_remove_col2" mat-flat-button color="accent" class="col-12 e-btn-column"
                    (click)="imgRemove('img_col2'); onNameChange();">Remove Column
                    2</button>
                </div>
                <div class="row m-1">
                  <button *ngIf="!btn3" id="btn_add_col3" mat-flat-button color="primary" class="col-12 e-btn-column"
                    (click)="insertImage3()">Add Column
                    3</button>
                  <button *ngIf="btn3" id="btn_remove_col3" mat-flat-button color="accent" class="col-12 e-btn-column"
                    (click)="imgRemove('img_col3'); onNameChange();">Remove Column
                    3</button>
                </div>
                <div class="row m-1">
                  <button *ngIf="!btn4" id="btn_add_col4" mat-flat-button color="primary" class="col-12 e-btn-column"
                    (click)="insertImage4()">Add Column
                    4</button>
                  <button *ngIf="btn4" id="btn_remove_col4" mat-flat-button color="accent" class="col-12 e-btn-column"
                    (click)="imgRemove('img_col4'); onNameChange();">Remove Column
                    4</button>
                </div>
                <div class="row m-1">
                  <button *ngIf="!btn5" id="btn_add_col5" mat-flat-button color="primary" class="col-12 e-btn-column"
                    (click)="insertImage5()">Add Column
                    5</button>
                  <button *ngIf="btn5" id="btn_remove_col5" mat-flat-button color="accent" class="col-12 e-btn-column"
                    (click)="imgRemove('img_col5'); onNameChange();">Remove Column
                    5</button>
                </div>
                <div class="row m-1">
                  <button *ngIf="!btn6" id="btn_add_col6" mat-flat-button color="primary" class="col-12 e-btn-column"
                    (click)="insertImage6()">Add Column
                    6</button>
                  <button *ngIf="btn6" id="btn_remove_col6" mat-flat-button color="accent" class="col-12 e-btn-column"
                    (click)="imgRemove('img_col6'); onNameChange();">Remove Column
                    6</button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-error *ngIf="emptyMessage" style="font-size: small;">Can't be empty</mat-error>
                <mat-error *ngIf="keyValueUsed" style="font-size: small;">Can't used key value of <b>"#eSMS_VAR"</b>
                  here</mat-error>
              </div>
            </div>
            <div class="row mt-2" *ngIf="this.account?.account_ussage == 1">
              <div class="col-12 e-cb-wrapper">
                <mat-checkbox class="e-cb-wrapper__cb" formControlName="isOptOutRequired">
                  Is OPT OUT required?</mat-checkbox>
              </div>
            </div>
            <hr>
            <div class="row e-btn-group e-btn-group--center mt-2">
              <button mat-stroked-button class="e-btn-stroke mr-2" type="button" [routerLink]="['/campaign/list']">
                CANCEL
              </button>
              <button mat-flat-button color="primary" class="e-btn ml-2" (click)="createCampaign()">
                CREATE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
