import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CampaignService } from "src/app/campaign/campaign.service";
import { ErrorDialogBoxComponent } from "src/app/pop-up-messages/error-dialog-box/error-dialog-box.component";
import { ReadMessagePopupDialogComponent } from "../inbox.component";
import * as XLSX from "xlsx";

@Component({
  selector: "app-inbox-all",
  templateUrl: "./inbox-all.component.html",
  styleUrls: ["./inbox-all.component.css"],
})
export class InboxAllComponent implements OnInit {
  form;
  isLoading = false;
  isRefresh = false;

  loadedData = true;

  shortCodeList;

  limit = 40;

  dataSourceAll;

  totalPosts = 0;
  postsPerPage = 5;
  pageSizeOption = [5, 10, 25, 100];
  currentPage = 1;

  dataSourceOne;
  displayedColumnsOne: string[] = [
    "id",
    "timestamp",
    "sender",
    "receiver",
    "message",
  ];
  @ViewChild("TableOnePaginator", { static: false })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort") tableOneSort: MatSort;

  constructor(
    private textCapaignService: CampaignService,
    private datePipe: DatePipe,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    // this.fetchData(this.currentPage, this.postsPerPage);
    this.form = new FormGroup({
      shortCode: new FormControl(null, {
        validators: [Validators.required],
      }),
      fromDate: new FormControl(null, {
        validators: [Validators.required],
      }),
      toDate: new FormControl(null, {
        validators: [Validators.required],
      }),
    });

    this.fetchShortCodeList();
  }

  formatContent(content: string) {
    if (content.substr(0, this.limit) == content) {
      return content;
    } else {
      return `${content.substr(
        0,
        this.limit
      )}<font color="blue">...<a (click)="readMoreDialog(element.receiver, element.id, element.message, element.status)">see more</a></font>`;
    }
  }

  public dateFilterFrom = (d: Date): boolean => {
    const value = this.form.value;
    if (value.toDate) {
      return true && d <= this.toDate(value.toDate);
    } else {
      return true;
    }
  };

  public dateFilterTo = (d: Date): boolean => {
    const value = this.form.value;
    if (value.fromDate) {
      return d >= this.toDate(value.fromDate) && true;
    } else {
      return true;
    }
  };

  protected toDate(d: Date | string): Date {
    return typeof d === "string" ? new Date(d) : d;
  }

  fetchShortCodeList() {
    this.textCapaignService.getShortCodeList().subscribe(
      (response: any) => {
        ////////console.log(response);
        this.shortCodeList = response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  fetchData(currentPage, postsPerPage, shortCode, fromDate, ToDate) {
    this.isLoading = true;
    this.dataSourceOne = new MatTableDataSource(null);
    //get campaign list

    this.textCapaignService
      .getMessageList(
        currentPage,
        postsPerPage.toString(),
        shortCode,
        fromDate,
        ToDate
      )
      .subscribe(
        (response: any) => {
          ////////console.log(response);
          // this.totalPosts = response.data.count;
          ////////console.log(this.totalPosts);
          this.dataSourceAll = response?.data?.message_list||[];
          this.dataSourceOne = new MatTableDataSource(this.dataSourceAll);
          this.dataSourceOne.sort = this.tableOneSort;
          if (this.dataSourceAll.length < this.postsPerPage) {
            this.totalPosts = this.currentPage * this.postsPerPage;
          } else {
            this.totalPosts = this.currentPage * this.postsPerPage + 1;
          }
          this.isLoading = false;
          this.isRefresh = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isRefresh = false;
        }
      );
  }

  applySearch(formDirective: FormGroupDirective) {
    if (this.form.invalid) {
      return;
    }

    this.isRefresh = true;

    this.postsPerPage = 5;
    this.pageSizeOption = [5, 10, 25, 100];
    this.currentPage = 1;

    this.fetchData(
      this.currentPage,
      this.postsPerPage,
      this.form.get("shortCode").value,
      this.datePipe.transform(this.form.get("fromDate").value, "yyyy-MM-dd"),
      this.datePipe.transform(this.form.get("toDate").value, "yyyy-MM-dd")
    );
  }

  onChangedPage(pageData: PageEvent) {
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchData(
      this.currentPage,
      this.postsPerPage,
      this.form.get("shortCode").value,
      this.datePipe.transform(this.form.get("fromDate").value, "yyyy-MM-dd"),
      this.datePipe.transform(this.form.get("toDate").value, "yyyy-MM-dd")
    );
  }

  readMoreDialog(
    short_code: string,
    message_id: string,
    message: string,
    msg_status: string
  ): void {

    const dialogRef = this.matDialog.open(ReadMessagePopupDialogComponent, {
      width: "542px",

      data: {
        short_code: short_code,
        message_id: message_id,
        message: message,
        msg_status: msg_status,
      },
    });
    const subscribeDialog = dialogRef.componentInstance.onSubmitRequest.subscribe((data) => {
      this.fetchData(
        this.currentPage,
        this.postsPerPage,
        this.form.get("shortCode").value,
        this.datePipe.transform(this.form.get("fromDate").value, "yyyy-MM-dd"),
        this.datePipe.transform(this.form.get("toDate").value, "yyyy-MM-dd")
      );
    });
    dialogRef.afterClosed().subscribe((result) => {
      subscribeDialog.unsubscribe();
    });
  }

  fetchedCampaignStatus(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "Pending";
        break;
      case 1:
        status_string = "Running";
        break;
      case 2:
        status_string = "Completed";
        break;
      case 3:
        status_string = "Deleted ";
        break;
      case 4:
        status_string = "Modified";
        break;
      case 5:
        status_string = "Paused";
        break;
      case 6:
        status_string = "Expired";
        break;
      case 7:
        status_string = "Two phase locked ";
        break;
      case 8:
        status_string = "Stopped";
        break;
      case 9:
        status_string = "Waiting for payment";
        break;
      case 11:
        status_string = "Payment failed";
        break;
      case 12:
        status_string = "Waiting for EzCash";
        break;
      case 14:
        status_string = "Admin review pending";
        break;
      case 15:
        status_string = "Admin review rejected";
        break;
      case 16:
        status_string = "On pause after reaching blackout";
        break;
      case 17:
        status_string = "Stopped after reaching blackout";
        break;
      default:
        status_string = "Inactive";
        break;
    }
    return status_string;
  }

  downloadExcel(){

    this.loadedData = false;
    this.textCapaignService
    .getMessageList(
      "1",
      "all",
      this.form.get("shortCode").value,
      this.datePipe.transform(this.form.get("fromDate").value, "yyyy-MM-dd"),
      this.datePipe.transform(this.form.get("toDate").value, "yyyy-MM-dd")
    ).subscribe(
      (response: any) => {
        if (response.status == "success") {

          var data = response?.data?.message_list?.map((a) => ({
            id: a.id,
            time: a.timestamp.replace('Z', '').replace('T', ' '),
            sender: a.sender,
            receiver: a.receiver,
            message: a.message,
            status: a.status==1?"Read":"Unread"
          }))||[];
          var dataSource = new MatTableDataSource(data);
          this.exportInbox(dataSource);
          this.loadedData = true;
        } else {
          this.loadedData = true;

          this.matDialog.open(ErrorDialogBoxComponent, {
            width: "532px",
            data: {
              message: response.comment,
            },
          });
        }
      },
      (error) => {
        this.loadedData = true;
        console.log(error);
      }
    );
  }

  exportInbox(dataSource): void {
    const Heading = [
      [
        "ID",
        "Time",
        "Sender",
        "Receiver",
        "Message",
        "Status"
      ],
    ];

    /* add the header */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, dataSource.filteredData, {
      origin: "A2",
      skipHeader: true,
    });

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(
      wb,
      "Inbox_All_" + new Date().getTime() + ".xlsx"
    );
  }
}
