import { DatePipe, formatDate, Location } from "@angular/common";
import {
  Component,
  Inject,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { SuccessDialogBoxComponent } from "src/app/pop-up-messages/success-dialog-box/success-dialog-box.component";
import { CampaignService } from "../campaign.service";
import * as XLSX from "xlsx";
export interface DialogData {
  campaignId: string;
  campaignName: string;
}

@Component({
  selector: "app-campaign-view",
  templateUrl: "./campaign-view.component.html",
  styleUrls: ["./campaign-view.component.css"],
})
export class CampaignViewComponent implements OnInit {
  form;

  isClickContactList = true;
  isLoading = true;
  isRefresh = false;
  isAllPaginatorChanged = false;
  isSearchPaginatorChanged = false;
  loadedDataCampaignView = false;

  responseData = [];
  onlyShowSelect = "1";
  loadingTable = false;
  selected = 1;
  scheduleDateUTC;
  currentDateUTC;
  dialogRef;
  campaign = null;

  dataSourceAllOne;

  totalPostsOne = 0;
  postsPerPageOne = 5;
  pageSizeOptionOne = [5, 10, 25, 100];
  currentPageOne = 1;

  displayedColumnsOne: string[] = ["msisdn", "status"];
  dataSourceOne = new MatTableDataSource();
  @ViewChild("TableOnePaginator", { static: false })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort") tableOneSort: MatSort;

  messageSentTypes = [
    { name: "ALL", value: "ALL" },
    { name: "Not Sent", value: 0 },
    { name: "Sent", value: 1 },
    { name: "Sending Failed", value: 2 },
    { name: "Delivered", value: 3 },
    { name: "Not Delivered", value: 4 },
  ];

  totalPostsTwo = 0;
  postsPerPageTwo = 5;
  pageSizeOptionTwo = [5, 10, 25, 100];
  currentPageTwo = 1;

  dataSourceAllTwo;

  dataSourceTwo;
  displayedColumnsTwo: string[] = ["msisdn", "status"];
  @ViewChild("TableTwoPaginator", { static: false })
  tableTwoPaginator: MatPaginator;
  @ViewChild("TableTwoSort") tableTwoSort: MatSort;

  constructor(
    private location: Location,
    private textCapaignService: CampaignService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      messageSent: new FormControl("ALL", {
        validators: [Validators.required],
      }),
      searchParam: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(11)],
      }),
    });

    if (this.route.snapshot.params["id"]) {
      this.fetchAllData(
        this.currentPageOne,
        this.postsPerPageOne,
        "ALL",
        this.route.snapshot.params["id"]
      );
    } else {
      this.router.navigate(["/campaign/list"]);
    }
  }

  fetchAllData(currentPage, postsPerPage, messageSent, id) {
    this.isLoading = true;
    this.dataSourceOne = new MatTableDataSource(null);
    //get campaign list

    this.textCapaignService
      .getCampaignViewContactList(
        currentPage,
        postsPerPage.toString(),
        messageSent,
        id
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          // this.totalPostsOne = response.count;
          //////console.log(this.totalPosts);
          this.dataSourceAllOne = response?.numberList || [];
          this.dataSourceOne = new MatTableDataSource(this.dataSourceAllOne);
          this.dataSourceOne.sort = this.tableOneSort;

          if (this.dataSourceAllOne.length < this.postsPerPageOne) {
            this.totalPostsOne = this.currentPageOne * this.postsPerPageOne;
          } else {
            this.totalPostsOne = this.currentPageOne * this.postsPerPageOne + 1;
          }

          this.campaign = {
            campaign_cost: response.campaign.campaign_cost,
            campaign_messages:
              response.campaign.d2d_numbers + response.campaign.d2nd_numbers,
            campaign_name: response.campaign.campaign_name,
            campaign_ready_status: response.campaign.campaign_ready_status,
            campaign_type: response.campaign.campaign_type,
            created_time: response.campaign.created_time,
            creator_id: response.campaign.creator_id,
            expiry_time: response.campaign.expiry_time,
            id: response.campaign.id,
            message_id: response.campaign.message_id,
            schedule_time: response.campaign.schedule_time,
            source_address: response.campaign.source_address,
            status: response.campaign.status,
            text: response.campaign.text.replace(/ /g, "\u00a0"),
            transaction_id: response.campaign.transaction_id,
            is_server_number_file_processing_running:
              response.campaign.is_server_number_file_processing_running,
            is_waiting_for_server_number_file_uploads:
              response.campaign.is_waiting_for_server_number_file_uploads,
            black_out_logic: response.campaign.image_size,
          };

          this.isAllPaginatorChanged = false;
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isAllPaginatorChanged = false;
          this.isLoading = false;
          this.router.navigate(["/campaign/list"]);
        }
      );
  }

  onChangedAllPage(pageData: PageEvent) {
    this.currentPageOne = pageData.pageIndex + 1;
    this.postsPerPageOne = pageData.pageSize;
    this.isAllPaginatorChanged = true;
    if (this.route.snapshot.params["id"]) {
      this.fetchAllData(
        this.currentPageOne,
        this.postsPerPageOne,
        "ALL",
        this.route.snapshot.params["id"]
      );
    } else {
      this.router.navigate(["/campaign/list"]);
    }
  }

  fetchSearchData(currentPage, postsPerPage, messageSent, searchParam, id) {
    this.isLoading = true;
    this.dataSourceOne = new MatTableDataSource(null);
    //get campaign list

    this.textCapaignService
      .getCampaignViewSearchContactList(
        currentPage,
        postsPerPage.toString(),
        messageSent.toString(),
        searchParam,
        id
      )
      .subscribe(
        (response: any) => {
          ////////console.log(response);
          // this.totalPostsTwo = response.count;
          ////////console.log(this.totalPosts);
          this.dataSourceAllTwo = response?.numberList || [];
          this.dataSourceTwo = new MatTableDataSource(this.dataSourceAllTwo);
          this.dataSourceTwo.sort = this.tableTwoSort;
          if (this.dataSourceAllTwo.length < this.postsPerPageTwo) {
            this.totalPostsTwo = this.currentPageTwo * this.postsPerPageTwo;
          } else {
            this.totalPostsTwo = this.currentPageTwo * this.postsPerPageTwo + 1;
          }
          // this.cdr.detectChanges();
          this.isLoading = false;
          this.isRefresh = false;
          this.isSearchPaginatorChanged = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isRefresh = false;
          this.isSearchPaginatorChanged = false;
          this.router.navigate(["/campaign/list"]);
        }
      );
  }

  applySearch(formDirective: FormGroupDirective) {
    if (this.form.invalid) {
      return;
    }

    this.isRefresh = true;
    this.isSearchPaginatorChanged = true;

    this.postsPerPageTwo = 5;
    this.pageSizeOptionTwo = [5, 10, 25, 100];
    this.currentPageTwo = 1;

    let mobileNumber;
    if (this.form.value.searchParam.length == 9) {
      mobileNumber = this.form.value.searchParam;
    } else if (this.form.value.searchParam.length == 10) {
      mobileNumber = this.form.value.searchParam.substr(1);
    } else if (this.form.value.searchParam.length == 11) {
      mobileNumber = this.form.value.searchParam.substr(2);
    } else {
      mobileNumber = this.form.value.searchParam;
    }

    if (this.route.snapshot.params["id"]) {
      this.fetchSearchData(
        this.currentPageTwo,
        this.postsPerPageTwo,
        this.form.get("messageSent").value,
        mobileNumber,
        this.route.snapshot.params["id"]
      );
    } else {
      this.router.navigate(["/campaign/list"]);
    }
  }

  onChangedSearchPage(pageData: PageEvent) {
    //////console.log(pageData);
    this.currentPageTwo = pageData.pageIndex + 1;
    this.postsPerPageTwo = pageData.pageSize;
    this.isSearchPaginatorChanged = true;

    let mobileNumber;
    if (this.form.value.searchParam.length == 9) {
      mobileNumber = this.form.value.searchParam;
    } else if (this.form.value.searchParam.length == 10) {
      mobileNumber = this.form.value.searchParam.substr(1);
    } else if (this.form.value.searchParam.length == 11) {
      mobileNumber = this.form.value.searchParam.substr(2);
    } else {
      mobileNumber = this.form.value.searchParam;
    }

    if (this.route.snapshot.params["id"]) {
      this.fetchSearchData(
        this.currentPageTwo,
        this.postsPerPageTwo,
        this.form.get("messageSent").value,
        mobileNumber,
        this.route.snapshot.params["id"]
      );
    } else {
      this.router.navigate(["/campaign/list"]);
    }
  }

  formatTemplatedMessage(msg) {
    var htmlMsg = msg
      .replaceAll(
        "#eSMS_VAR_2",
        '<img src="assets/images/col2_tag.png" id="img_col2" width="60">'
      )
      .replaceAll(
        "#eSMS_VAR_3",
        '<img src="assets/images/col3_tag.png" id="img_col3" width="60">'
      )
      .replaceAll(
        "#eSMS_VAR_4",
        '<img src="assets/images/col4_tag.png" id="img_col4" width="60">'
      )
      .replaceAll(
        "#eSMS_VAR_5",
        '<img src="assets/images/col5_tag.png" id="img_col5" width="60">'
      )
      .replaceAll(
        "#eSMS_VAR_6",
        '<img src="assets/images/col6_tag.png" id="img_col6" width="60">'
      )
      .replaceAll("\n", "<br>");

    return htmlMsg;
  }
  getDeliveredStatus(status) {
    let stringStatus;
    switch (status) {
      case 0:
        stringStatus = "Not Sent";
        break;
      case 1:
        stringStatus = "Sent";
        break;
      case 2:
        stringStatus = "Sending Failed";
        break;
      case 3:
        stringStatus = "Delivered";
        break;
      case 4:
        stringStatus = "Not Delivered";
        break;
      default:
        stringStatus = null;
        break;
    }
    return stringStatus;
  }
  campaignStop() {
    const dialogRef = this.matDialog.open(CampaignStopComponentPopUpDialog, {
      width: "542px",
      data: {
        campaignId: this.campaign.id,
        campaignName: this.campaign.campaign_name,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.isLoading = true;
      if (result.data == true) {
        // this.textCapaignService
        //   .getSingleCampaign(this.route.snapshot.params["id"])
        //   .subscribe(
        //     (response: any) => {
        //       if (response.campaign && response.status == "success") {
        //         let tableData = [];
        //         tableData = response.numberList.map((item) => {
        //           return {
        //             msisdn: item.msisdn,
        //             status: this.getDeliveredStatus(item.message_sent),
        //           };
        //         });
        //         this.responseData = tableData;
        //         //console.log(response);
        //         this.campaign = {
        //           campaign_cost: response.campaign.campaign_cost,
        //           campaign_messages: response.numberBase[0].count,
        //           campaign_name: response.campaign.campaign_name,
        //           campaign_ready_status:
        //             response.campaign.campaign_ready_status,
        //           campaign_type: response.campaign.campaign_type,
        //           created_time: response.campaign.created_time,
        //           creator_id: response.campaign.creator_id,
        //           expiry_time: response.campaign.expiry_time,
        //           id: response.campaign.id,
        //           message_id: response.campaign.message_id,
        //           schedule_time: response.campaign.schedule_time,
        //           source_address: response.campaign.source_address,
        //           status: response.campaign.status,
        //           text: response.campaign.text,
        //           transaction_id: response.campaign.transaction_id,
        //         };
        //         this.scheduleDateUTC = formatDate(
        //           this.campaign.schedule_time.toString(),
        //           "yyyy-MM-dd hh:mm:ss a",
        //           "en-US",
        //           "+0000"
        //         );
        //         this.currentDateUTC = formatDate(
        //           new Date(),
        //           "yyyy-MM-dd hh:mm:ss a",
        //           "en-US",
        //           "+0000"
        //         );

        //         this.isLoading = false;
        //         this.loadingTable = true;
        //         //console.log(this.campaign);
        //       } else {
        //         this.router.navigate(["/campaign/list"]);
        //       }
        //     },
        //     (error) => {
        //       // console.log(error);
        //       this.router.navigate(["/campaign/list"]);
        //     }
        //   );

        if (this.route.snapshot.params["id"]) {
          this.fetchAllData(
            this.currentPageOne,
            this.postsPerPageOne,
            "ALL",
            this.route.snapshot.params["id"]
          );
        } else {
          this.router.navigate(["/campaign/list"]);
        }
      }
    });
  }
  //removed
  campaignResume() {
    // this.textCapaignService.campaignStatusChange(this.campaign.id, 0).subscribe(
    //   (response: any) => {
    //     if (response.status == "success") {
    //       this.campaign.status = "0";
    //     }
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  }

  downloadExcel(): void {
    this.loadedDataCampaignView = true;

    this.textCapaignService
      .getCampaignViewContactList(
        "1",
        "all",
        "ALL",
        this.route.snapshot.params["id"]
      )
      .subscribe(
        (response: any) => {
          var data =
            response?.numberList?.map((a) => ({
              id: a.id,
              mobile: a.msisdn,
              status: this.getDeliveredStatus(a.message_sent),
            })) || [];
          var dataSource = new MatTableDataSource(data);

          const Heading = [["ID", "MSISDN", "STATUS"]];

          /* add the header */
          const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_aoa(ws, Heading);

          //Starting in the second row to avoid overriding and skipping headers
          XLSX.utils.sheet_add_json(ws, dataSource.filteredData, {
            origin: "A2",
            skipHeader: true,
          });

          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

          /* save to file */
          XLSX.writeFile(
            wb,
            "Campaign_" +
              this.route.snapshot.params["id"] +
              new Date().getTime() +
              ".xlsx"
          );
          // this.isLoading = false;
          this.loadedDataCampaignView = false;
        },
        (error) => {
          console.log(error);
          // this.isLoading = false;
          this.loadedDataCampaignView = false;
        }
      );
  }
  fetchedCampaignStatus(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "Pending";
        break;
      case 1:
        status_string = "Running";
        break;
      case 2:
        status_string = "Completed";
        break;
      case 3:
        status_string = "Deleted ";
        break;
      case 4:
        status_string = "Modified";
        break;
      case 5:
        status_string = "Paused";
        break;
      case 6:
        status_string = "Expired";
        break;
      case 7:
        status_string = "Two phase locked ";
        break;
      case 8:
        status_string = "Stopped";
        break;
      case 9:
        status_string = "Waiting for payment";
        break;
      case 11:
        status_string = "Payment failed";
        break;
      case 12:
        status_string = "Waiting for EzCash";
        break;
      case 14:
        status_string = "Admin review pending";
        break;
      case 15:
        status_string = "Admin review rejected";
        break;
      case 16:
        status_string = "On pause after reaching blackout";
        break;
      case 17:
        status_string = "Stopped after reaching blackout";
        break;
      default:
        status_string = "Inactive";
        break;
    }
    return status_string;
  }

  headerClick(val) {
    this.isClickContactList = val;
    if (!val) {
      this.form.patchValue({ searchParam: null });
      this.form.get("searchParam").updateValueAndValidity();
      this.form.patchValue({ messageSent: "ALL" });
      this.form.get("messageSent").updateValueAndValidity();
      this.dataSourceTwo = new MatTableDataSource(null);
    } else {
      this.isAllPaginatorChanged = true;
      if (this.route.snapshot.params["id"]) {
        this.fetchAllData(
          this.currentPageOne,
          this.postsPerPageOne,
          "ALL",
          this.route.snapshot.params["id"]
        );
      } else {
        this.router.navigate(["/contact/list"]);
      }
    }
  }

  onBackPressed(): void {
    this.location.back();
  }
}

@Component({
  selector: "campiagn-stop-popup-dialog",
  templateUrl: "./campaign-stop-modal.component.html",
  styleUrls: ["./campaign-stop-modal.component.css"],
})
export class CampaignStopComponentPopUpDialog implements OnInit {
  selectedPaymentOption = null;
  transactionStatus = false;
  disableYes = false;
  ngOnInit() {
    if (this.data.campaignId == null) {
      this.closeDialog();
    }
  }
  constructor(
    public dialogRef: MatDialogRef<CampaignStopComponentPopUpDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private campaignService: CampaignService,
    private router: Router,
    private matDialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeDialog() {
    this.router.navigate(["/campaign/view", this.data.campaignId]);
    this.dialogRef.close({ event: "close", data: this.transactionStatus });
  }

  stopCampaign() {
    this.transactionStatus = false;
    this.disableYes = true;
    if (!this.data.campaignId) {
      this.disableYes = false;
      return;
    } else {
      this.campaignService
        .campaignStatusChange(+this.data.campaignId, 8)
        .subscribe(
          (response: any) => {
            this.transactionStatus = true;
            this.disableYes = false;
            this.closeDialog();
            if (response.status == "success") {
              this.matDialog.open(SuccessDialogBoxComponent, {
                width: "532px",
                data: { message: response.comment },
              });
            }
          },
          (error) => {
            this.disableYes = false;
            this.dialogRef.close();
            // console.log(error);
          }
        );
    }
  }
}
