import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from "@angular/forms";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { CampaignService } from "src/app/campaign/campaign.service";

@Component({
  selector: "app-campaigns-search",
  templateUrl: "./campaigns-search.component.html",
  styleUrls: ["./campaigns-search.component.css"],
})
export class CampaignsSearchComponent implements OnInit {
  form;
  isLoading = false;
  isRefresh = false;

  campaignTypes = [
    { name: "ALL", value: "ALL" },
    { name: "PENDING", value: 0 },
    { name: "RUNNING", value: 1 },
    { name: "COMPLETED", value: 2 },
    { name: "DELETED", value: 3 },
    { name: "MODIFIED", value: 4 },
    { name: "PAUSED", value: 5 },
    { name: "EXPIRED", value: 6 },
    { name: "TWO-PHASE-LOCKED", value: 7 },
    { name: "STOPPED", value: 8 },
    { name: "WAITING-FOR-PAYMENT", value: 9 },
    { name: "PAYMENT-FAILED", value: 11 },
    { name: "WAITING-FOR-EzCash", value: 12 },
  ];

  totalPosts = 0;
  postsPerPage = 5;
  pageSizeOption = [5, 10, 25, 100];
  currentPage = 1;

  dataSourceAll;

  dataSourceOne;
  displayedColumnsOne: string[] = [
    "id",
    "campaign_name",
    "campaign_type",
    "source_address",
    "created_time",
    "schedule_time",
    "status",
    "message_id",
  ];
  @ViewChild("TableOnePaginator", { static: false })
  tableOnePaginator: MatPaginator;
  @ViewChild("TableOneSort") tableOneSort: MatSort;
  @ViewChild(MatSort) set TableOneSort(tableOneSort: MatSort) {
    if (this.dataSourceOne) {
      this.dataSourceOne.sort = tableOneSort;
    }
  }

  constructor(
    private textCapaignService: CampaignService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      campaignType: new FormControl("ALL", {
        validators: [Validators.required],
      }),
      searchParam: new FormControl(null, {
        validators: [Validators.required],
      }),
    });
  }

  fetchData(currentPage, postsPerPage, campaignType, searchParam) {
    this.isLoading = true;
    this.dataSourceOne = new MatTableDataSource(null);
    //get campaign list

    this.textCapaignService
      .campaignGlobalSearch(
        currentPage,
        postsPerPage.toString(),
        campaignType,
        searchParam
      )
      .subscribe(
        (response: any) => {
          // console.log(response);
          // this.totalPosts = response.count;
          ////////console.log(this.totalPosts);
          this.dataSourceAll = response?.campaignList || [];
          this.dataSourceOne = new MatTableDataSource(this.dataSourceAll);
          this.dataSourceOne.sort = this.tableOneSort;
          // this.cdr.detectChanges();
          if (this.dataSourceAll.length < this.postsPerPage) {
            this.totalPosts = this.currentPage * this.postsPerPage;
          } else {
            this.totalPosts = this.currentPage * this.postsPerPage + 1;
          }
          this.isLoading = false;
          this.isRefresh = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isRefresh = false;
        }
      );
  }

  applySearch(formDirective: FormGroupDirective) {
    if (this.form.invalid) {
      return;
    }

    this.isRefresh = true;

    this.postsPerPage = 5;
    this.pageSizeOption = [5, 10, 25, 100];
    this.currentPage = 1;

    this.fetchData(
      this.currentPage,
      this.postsPerPage,
      this.form.get("campaignType").value,
      this.form.get("searchParam").value
    );
  }

  onChangedPage(pageData: PageEvent) {
    //////console.log(pageData);
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchData(
      this.currentPage,
      this.postsPerPage,
      this.form.get("campaignType").value,
      this.form.get("searchParam").value
    );
  }

  fetchedCampaignStatus(status) {
    let status_string = null;
    switch (status) {
      case 0:
        status_string = "Pending";
        break;
      case 1:
        status_string = "Running";
        break;
      case 2:
        status_string = "Completed";
        break;
      case 3:
        status_string = "Deleted ";
        break;
      case 4:
        status_string = "Modified";
        break;
      case 5:
        status_string = "Paused";
        break;
      case 6:
        status_string = "Expired";
        break;
      case 7:
        status_string = "Two phase locked ";
        break;
      case 8:
        status_string = "Stopped";
        break;
      case 9:
        status_string = "Waiting for payment";
        break;
      case 11:
        status_string = "Payment failed";
        break;
      case 12:
        status_string = "Waiting for EzCash";
        break;
      case 14:
        status_string = "Admin review pending";
        break;
      case 15:
        status_string = "Admin review rejected";
        break;
      case 16:
        status_string = "On pause after reaching blackout";
        break;
      case 17:
        status_string = "Stopped after reaching blackout";
        break;
      default:
        status_string = "Inactive";
        break;
    }
    return status_string;
  }
}
